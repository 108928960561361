export default [
  {
    path: "/admins",
    name: "admin.list",
    meta: {
      title: "Administrators",
      authRequired: true,
      resource: 'Admin',
      action: 'admins.view',
    },
    component: () => import("@/views/admins/index.vue"),
  },
  {
    path: "/permission-groups",
    name: "admin.permission-groups",
    meta: {
      title: "Permission Groups",
      authRequired: true,
      resource: 'Admin',
      action: 'permission_groups.view',
    },
    component: () => import("@/views/permissionGroups/index.vue"),
  },
  {
    path: "/permissions",
    name: "admin.permissions",
    meta: {
      title: "Permissions",
      authRequired: true,
      resource: 'Admin',
      action: 'permissions.view',
    },
    component: () => import("@/views/permissions/index.vue"),
  },
  {
    path: "/roles",
    name: "admin.roles",
    meta: {
      title: "Roles",
      authRequired: true,
      resource: 'Admin',
      action: 'roles.view',
    },
    component: () => import("@/views/roles/index.vue"),
  },
  {
    path: "/member-groups",
    name: "member.member-groups",
    meta: {
      title: "Members",
      authRequired: true,
      resource: 'Admin',
      action: 'member_groups.view',
    },
    component: () => import("@/views/memberGroups/index.vue"),
  },
  {
    path: "/members",
    name: "member.members",
    meta: {
      title: "Members",
      authRequired: true,
      resource: 'Admin',
      action: 'members.view',
    },
    component: () => import("@/views/members/index.vue"),
  },
  {
    path: "/member-banks",
    name: "member.member-banks",
    meta: {
      title: "Members",
      authRequired: true,
      resource: 'Admin',
      action: 'member_banks.view',
    },
    component: () => import("@/views/memberBanks/index.vue"),
  },
  {
    path: "/invitation-codes",
    name: "member.invitation-codes",
    meta: {
      title: "Members",
      authRequired: true,
      resource: 'Admin',
      action: 'invitation_codes.view',
    },
    component: () => import("@/views/invitationCodes/index.vue"),
  },
  {
    path: "/transactions/deposits",
    name: "transactions.deposits",
    meta: {
      title: "Transactions",
      authRequired: true,
      resource: 'Admin',
      action: 'deposits.view',
    },
    component: () => import("@/views/deposits/index.vue"),
  },
  {
    path: "/transactions/withdraws",
    name: "transactions.withdraws",
    meta: {
      title: "Transactions",
      authRequired: true,
      resource: 'Admin',
      action: 'withdraws.view',
    },
    component: () => import("@/views/withdraws/index.vue"),
  },
  {
    path: "/transactions/adjustments",
    name: "transactions.adjustments",
    meta: {
      title: "Transactions",
      authRequired: true,
      resource: 'Admin',
      action: 'adjustments.view',
    },
    component: () => import("@/views/adjustments/index.vue"),
  },
  {
    path: "/projects/project-categories",
    name: "projects.project-categories",
    meta: {
      title: "Projects",
      authRequired: true,
      resource: 'Admin',
      action: 'project_categories.view',
    },
    component: () => import("@/views/projectCategories/index.vue"),
  },
  {
    path: "/projects",
    name: "projects",
    meta: {
      title: "Projects",
      authRequired: true,
      resource: 'Admin',
      action: 'projects.view',
    },
    component: () => import("@/views/projects/index.vue"),
  },
  {
    path: "/cms/banners",
    name: "cms-banners",
    meta: {
      title: "CMS",
      authRequired: true,
      resource: 'Admin',
      action: 'banners.view',
    },
    component: () => import("@/views/cms/banners/index.vue"),
  },
  {
    path: "/cms/announcements",
    name: "cms-announcements",
    meta: {
      title: "CMS",
      authRequired: true,
      resource: 'Admin',
      action: 'announcements.view',
    },
    component: () => import("@/views/cms/announcements/index.vue"),
  },
  {
    path: "/cms/news",
    name: "cms-news",
    meta: {
      title: "CMS",
      authRequired: true,
      resource: 'Admin',
      action: 'news.view',
    },
    component: () => import("@/views/cms/news/index.vue"),
  },
  {
    path: "/investments/join-records",
    name: "investments.join-records",
    meta: {
      title: "Investments",
      authRequired: true,
      resource: 'Admin',
      action: 'investments.view',
    },
    component: () => import("@/views/investments/join/index.vue"),
  },
  {
    path: "/investments/booking-records",
    name: "investments.booking-records",
    meta: {
      title: "Investments",
      authRequired: true,
      resource: 'Admin',
      action: 'bookings.view',
    },
    component: () => import("@/views/investments/booking/index.vue"),
  },
  {
    path: "/shop/products",
    name: "shop.products",
    meta: {
      title: "Shop",
      authRequired: true,
      resource: 'Admin',
      action: 'products.view',
    },
    component: () => import("@/views/shop/products/index.vue"),
  },
  {
    path: "/shop/orders",
    name: "shop.orders",
    meta: {
      title: "Shop",
      authRequired: true,
      resource: 'Admin',
      action: 'orders.view',
    },
    component: () => import("@/views/shop/orders/index.vue"),
  },
  {
    path: "/settings",
    name: "settings",
    meta: {
      title: "Settings",
      authRequired: true,
      resource: 'Admin',
      action: 'variables.view',
    },
    component: () => import("@/views/config/settings.vue"),
  },
];