import store from "@/state/store";

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/account/login.vue"),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
          next();
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      title: "Logout", 
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        localStorage.clear();
        sessionStorage.clear();
        next();
      },
    },
    component: () => import("@/views/account/logout")
  },
  {
    path: "/profile",
    name: "profile",
    meta: {
      title: "Profile", 
      authRequired: true,
      resource: 'Admin',
      action: 'profile.view',
    },
    component: () => import("@/views/account/profile")
  },
  {
    path: "/profile-setting",
    name: "profile-setting",
    meta: {
      title: "Profile Setting", 
      authRequired: true,
      resource: 'Admin',
      action: 'profile.update',
    },
    component: () => import("@/views/account/profile-setting")
  },
];