const options = {
  position: 'top-right',
	hideProgressBar: false,
  closeOnClick: false,
  closeButton: "button",
  icon: true,
  timeout: 5000,
  transition: 'Vue-Toastification__fade',
  newestOnTop: true,
  maxToasts: 5,
}

export default options;
