import axiosIns from '@/libs/axios';
import api from '@/libs/api';
import ability from "@/libs/acl/ability.js"
import { getFirebaseBackend } from '../../authUtils.js'

export const state = {
    token: localStorage.getItem('jwt'),
    currentUser: localStorage.getItem('userData'),
}

export const mutations = {
    SET_TOKEN(state, token) {
        state.token = token;
        saveState('jwt', token, false);
    },
    SET_CURRENT_USER(state, newValue) {
        state.currentUser = newValue;
        saveState('userData', newValue);
    },
}

export const getters = {
    // Whether the user is currently logged in.
    loggedIn(state) {
        return !!state.currentUser
    },
    currentUser(state) {
        return state.currentUser
    },
}

export const actions = {
    // This is automatically run in `src/state/store.js` when the app
    // starts, along with any other actions named `init` in other modules.
    // eslint-disable-next-line no-unused-vars
    init({ state, dispatch }) {
        dispatch('fetchUserData')
    },

    // Logs in the current user.
    logIn({ commit, dispatch, getters }, { username, password } = {}) {
        if (getters.loggedIn) return dispatch('validate')

        return new Promise((resolve, reject) => {
            axiosIns.post(api.API_LOGIN, {
                username: username,
                password: password,
            })
            .then(async (response) => {
                const result = response.data.data;
                commit('SET_TOKEN', result.token);

                await dispatch('fetchUserData');

                resolve(response)
            })
            .catch((error) => {
                reject(error);
            })
        });
    },

    // Logs out the current user.
    logOut({ commit }) {
        // eslint-disable-next-line no-unused-vars
        commit('SET_CURRENT_USER', null)
        return new Promise((resolve, reject) => {
            // eslint-disable-next-line no-unused-vars
            axiosIns.post(api.API_LOGOUT)
            .then((response) => {
                commit('SET_TOKEN', null);
                commit('SET_CURRENT_USER', null);

                ability.update([]);

                resolve(response);
            })
            .catch((error) => {
                reject(error);
            })
        });
    },

    // register the user
    register({ commit, dispatch, getters }, { username, email, password } = {}) {
        if (getters.loggedIn) return dispatch('validate')

        return getFirebaseBackend().registerUser(username, email, password).then((response) => {
            const user = response
            commit('SET_CURRENT_USER', user)
            return user
        });
    },

    // eslint-disable-next-line no-unused-vars
    resetPassword({ commit, dispatch, getters }, { email } = {}) {
        if (getters.loggedIn) return dispatch('validate')

        return getFirebaseBackend().forgetPassword(email).then((response) => {
            const message = response.data
            return message
        });
    },

    // Validates the current user's token and refreshes it
    // with new data from the API.
    // eslint-disable-next-line no-unused-vars
    validate({ commit, state }) {
        let userDataJson = localStorage.getItem('userData');
        const user = userDataJson && userDataJson!= 'undefined' ? JSON.parse(userDataJson) : null;
        if (!state.currentUser) return Promise.resolve(null);
        commit('SET_CURRENT_USER', user)
        return user;
    },

    // Get current user data
    fetchUserData({ commit }) {
        return new Promise((resolve, reject) => {
            axiosIns.get(api.API_PROFILE)
            .then((response) => {
                const userData = response.data.data;
                commit('SET_CURRENT_USER', userData);

                if (userData.abilities) {
                    ability.update(userData.abilities);
                }

                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
        });
    },
}

// ===
// Private helpers
// ===

function saveState(key, state, isJson=true) {
    if (isJson) {
        localStorage.setItem(key, JSON.stringify(state));
    } else {
        localStorage.setItem(key, state);
    }
}
