import moment from "moment";
import _ from "lodash";
import ToastificationContent from "@/components/toastification-content";
import { useToast } from "vue-toastification";

const helpers = {
  install(app) {
    const globalProperties = app.config.globalProperties;
    const toast = useToast();

    const toastSuccess = (title, text = '') => {
      toast.success({
        component: ToastificationContent,
        props: { title: title, text: text }
      })
    };
    const toastError = (title, text = '') => {
      toast.error({
        component: ToastificationContent,
        props: { title: title, text: text }
      })
    };
    const toastWarning = (title, text = '') => {
      toast.warning({
        component: ToastificationContent,
        props: { title: title, text: text }
      })
    };
    const toastInfo = (title, text = '') => {
      toast.info({
        component: ToastificationContent,
        props: { title: title, text: text }
      })
    };

    globalProperties.toastSuccess = toastSuccess;
    globalProperties.toastError = toastError;
    globalProperties.toastWarning = toastWarning;
    globalProperties.toastInfo = toastInfo;
    
    globalProperties.toLocalTime = (datetime, format='YYYY-MM-DD hh:mm:ss A') => {
      return datetime && isDateTime(datetime) ? moment.utc(datetime).local().format(format) : datetime;
    };

    globalProperties.toUtcTime = (datetime, format='YYYY-MM-DD hh:mm:ss A') => {
      return datetime ? moment.utc(moment(datetime, ["YYYY-MM-DD hh:mm:ss A", "YYYY-MM-DD HH:mm"]).utc()).format(format) : '';
    };

    const snakelizeObject = (obj) => {
      return _.transform(obj, (acc, value, key, target) => {
        const camelKey = _.isArray(target) ? key : _.snakeCase(key);
        acc[camelKey] = _.isObject(value) ? snakelizeObject(value) : value;
      })
    };
    globalProperties.snakelizeObject = snakelizeObject;

    globalProperties.resetObject = (obj) => {
      Object.keys(obj).forEach( (key) => {
        if (Array.isArray(obj[key])) {
          obj[key] = []
        } else if ( obj[key] !== null && typeof obj[key] == 'object') {
           Object.keys(obj[key]).forEach( (key2) => {
            obj[key][key2] = null;
           });
        } else {
          obj[key] = null;
        }
      });
    };

    globalProperties.errorHandler = (error) => {
      let {status, data} = error.response;
      let errorMessage = data.errors ?? data.message;
      if (_.isArray(errorMessage)) {
        errorMessage = data.errors.join('<br>');
      }
      toastError('Fail' + ' (Error: ' + status + ')', errorMessage);
    };

    const roundDown = (amount) => {
      return _.floor(amount, 2);
    }

    globalProperties.toCurrency = (amount, showCurrency=false, currencySymbol='MYR', rawNumber=false) => {
      let roundDownAmount = roundDown(amount);
      let currencyPrefix = {};
      currencyPrefix.minimumFractionDigits = 2;
      currencyPrefix.maximumFractionDigits = 2;

      if (rawNumber) currencyPrefix.useGrouping = false;

      if (showCurrency) {
        currencyPrefix.style = 'currency';
        currencyPrefix.currency = currencySymbol;
      }
      
      let formatter = new Intl.NumberFormat('en-US', currencyPrefix);
      return formatter.format(roundDownAmount);
    };

    const convertDateFormat = (datetime, format = 'YYYY-MM-DD HH:mm:ss') => {
      return datetime ? moment(datetime).format(format) : '';
    };

    const isDateTime = (datetime) => {
      if (datetime) {
        if (moment(datetime, [
          'YYYY-MM-DD hh:mm A',
          'YYYY-MM-DD h:mm A',
          'YYYY-MM-DD HH:mm',
          'YYYY-MM-DD HH:mm:ss',
          'YYYY-MM-DDTHH:mm:ss.SSSSSSZ'
        ], true).isValid()) {
          return true;
        }
      }
      return false;
    };

    const convertDateObject = (object, format = 'YYYY-MM-DD HH:mm:ss') => {
      let data = _.clone(object);
      Object.keys(data).forEach(key => {
        if (isDateTime(data[key])) {
          data[key] = convertDateFormat(data[key], format);
        }
      });

      return data;
    };

    globalProperties.convertDateFormat = convertDateFormat;
    globalProperties.isDateTime = isDateTime;
    globalProperties.convertDateObject = convertDateObject;
  } 
}
export default helpers;
