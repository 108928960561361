export default [
  {
    path: '/unauthorized',
    name: 'unauthorized',
    meta: {
      title: "Unauthorized", 
      authRequired: false,
    },
    component: () => import("@/views/errors/401")
  },
  {
    path: '/not-found',
    name: 'not-found',
    meta: {
      title: "404 Not Found", 
      authRequired: false,
    },
    component: () => import("@/views/errors/404")
  },
  {
    path: '/internal-server-error',
    name: 'internal-server-error',
    meta: {
      title: "500 Internal Server Error", 
      authRequired: false,
    },
    component: () => import("@/views/errors/500")
  },
  {
    path: '/offline',
    name: 'offline',
    meta: {
      title: "You Are Currently Offline", 
      authRequired: false,
    },
    component: () => import("@/views/errors/offline")
  },
];