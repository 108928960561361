import ability from './ability'

export const canNavigate = to => {
    if (to.meta.authRequired && to.meta.authRequired === true && to.meta.action !== null) {
        if (Array.isArray(to.meta.action)) {
            return to.meta.action.some(permission =>
                ability.can(permission || 'read', to.meta.resource)
            );
        } else {
            return to.matched.some(
                route => ability.can(route.meta.action || 'read', route.meta.resource),
            );
        }
    }
    return true;
};

export const _ = undefined
