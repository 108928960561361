export default [
  {
    path: "/",
    name: "default",
    meta: {
      title: "Dashboard",
      authRequired: true,
      resource: 'Admin',
      action: 'dashboard.view',
    },
    component: () => import("@/views/dashboard/index.vue"),
  },
];